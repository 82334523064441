@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,700");

body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  height: 100%;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.fb-login-button {
  margin-left: 10px;
}
